.back__arrow {
  width: 2rem;
  height: 2rem;
  background-color: var(--bs-primary);
  border-radius: 50%;
  position: absolute;
  left: 1rem;
  bottom: 40%;
  padding: 2px;
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    left: 1rem;
  }

  .disabled {
    opacity: 0.4;
  }
}
