.home_card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 6px -6px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: var(--bs-primary);
    cursor: pointer;
    color: var(--bs-gray-300);
    *,
    .icon {
      color: var(--bs-gray-300) !important;
    }
  }

  .icon {
    font-size: 2rem;
  }
}
