/*
 * CKEditor 5 (v36.0.1) content styles.
 * Generated on Thu, 09 Feb 2023 14:42:43 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
 */

:root {
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-table-caption-background: hsl(0, 0%, 97%);
  --ck-color-table-caption-text: hsl(0, 0%, 20%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
}
.image_resized img {
  width: 100%;
}

/* ckeditor5-font/theme/fontsize.css */
.text-tiny {
  font-size: 0.7em;
}
/* ckeditor5-font/theme/fontsize.css */
.text-small {
  font-size: 0.85em;
}
/* ckeditor5-font/theme/fontsize.css */
.text-big {
  font-size: 1.4em;
}
/* ckeditor5-font/theme/fontsize.css */
.text-huge {
  font-size: 1.8em;
}
/* ckeditor5-image/theme/image.css */
.image {
  display: table;
  clear: both;
  text-align: center;
  margin: 0.9em auto;
  min-width: 50px;
}
/* ckeditor5-image/theme/image.css */
.image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
}
/* ckeditor5-image/theme/image.css */
.image-inline {
  /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
  display: inline-flex;
  max-width: 100%;
  align-items: flex-start;
}
/* ckeditor5-image/theme/image.css */
.image-inline picture {
  display: flex;
}
/* ckeditor5-image/theme/image.css */
.image-inline picture,
.image-inline img {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}
/* ckeditor5-image/theme/imagecaption.css */
.image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: var(--ck-color-image-caption-text);
  background-color: var(--ck-color-image-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-image/theme/imagestyle.css */
.image-style-block-align-left,
.image-style-block-align-right {
  max-width: calc(100% - var(--ck-image-style-spacing));
}
/* ckeditor5-image/theme/imagestyle.css */
.image-style-align-left,
.image-style-align-right {
  clear: none;
}
/* ckeditor5-image/theme/imagestyle.css */
.image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}
/* ckeditor5-image/theme/imagestyle.css */
.image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.image-style-block-align-right {
  margin-right: 0;
  margin-left: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.image-style-block-align-left {
  margin-left: 0;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
p + .image-style-align-left,
p + .image-style-align-right,
p + .image-style-side {
  margin-top: 0;
}
/* ckeditor5-image/theme/imagestyle.css */
.image-inline.image-style-align-left,
.image-inline.image-style-align-right {
  margin-top: var(--ck-inline-image-style-spacing);
  margin-bottom: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.image-inline.image-style-align-left {
  margin-right: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.image-inline.image-style-align-right {
  margin-left: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imageresize.css */
.image.image_resized {
  max-width: 100% !important;
  display: block !important;
  box-sizing: border-box !important;
}
/* ckeditor5-image/theme/imageresize.css */
.image.image_resized img {
  width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.image.image_resized > figcaption {
  display: block !important;
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.media {
  clear: both;
  margin: 0.9em 0;
  display: block;
  min-width: 15em;
}
/* ckeditor5-table/theme/table.css */
.table {
  margin: 0.9em auto !important;
  display: table !important;
}
/* ckeditor5-table/theme/table.css */
.table table {
  border-collapse: collapse !important;
  border-spacing: 0 !important;
  width: 100% !important;
  height: 100% !important;
  border: 1px double hsl(0, 0%, 70%) !important;
}
/* ckeditor5-table/theme/table.css */
.table table td,
.table table th {
  min-width: 2em !important;
  padding: 0.4em !important;
  border: 1px solid hsl(0, 0%, 75%) !important;
}
/* ckeditor5-table/theme/table.css */
.table table th {
  font-weight: bold !important;
  background: hsla(0, 0%, 0%, 5%) !important;
}
/* ckeditor5-table/theme/table.css */
[dir='rtl'] .table th {
  text-align: right !important;
}
/* ckeditor5-table/theme/table.css */
[dir='ltr'] .table th {
  text-align: left !important;
}
/* ckeditor5-table/theme/tablecaption.css */
.table > figcaption {
  display: table-caption !important;
  caption-side: top !important;
  word-break: break-word !important;
  text-align: center !important;
  color: var(--ck-color-table-caption-text) !important;
  background-color: var(--ck-color-table-caption-background) !important;
  padding: 0.6em !important;
  font-size: 0.75em !important;
  outline-offset: -1px !important;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.table .ck-table-resized {
  table-layout: fixed !important;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.table table {
  overflow: hidden !important;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.table td,
.table th {
  position: relative !important;
}
blockquote {
  border-left: 5px solid #ccc !important;
  font-style: italic !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  overflow: hidden !important;
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
}
code {
  background-color: hsla(0, 0%, 78%, 0.3) !important;
  border-radius: 2px !important;
  padding: 0.15em !important;
}
.todo-list {
  list-style: none !important;
}
.todo-list .todo-list__label > input[checked]::before {
  background: #26ab33 !important;
  border-color: #26ab33 !important;
}
.todo-list .todo-list__label > input::after {
  border-color: transparent !important;
  border-style: solid !important;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
    calc(var(--ck-todo-list-checkmark-size) / 8) 0 !important;
  box-sizing: content-box !important;
  content: '' !important;
  display: block !important;
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6) !important;
  left: calc(var(--ck-todo-list-checkmark-size) / 3) !important;
  pointer-events: none !important;
  position: absolute !important;
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3) !important;
  transform: rotate(45deg) !important;
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3) !important;
}
.todo-list .todo-list__label > input[checked]::before {
  background: #26ab33 !important;
  border-color: #26ab33 !important;
}

.word-count {
  display: flex !important;
  justify-content: flex-end !important;
  background: var(--ck-color-toolbar-background) !important;
  padding: var(--ck-spacing-small) var(--ck-spacing-standard) !important;
  border: 1px solid var(--ck-color-toolbar-border) !important;
  border-top-width: 0 !important;
  border-radius: 0 0 var(--ck-border-radius) !important;
}

.word-count__words {
  margin-right: 2rem !important;
}

[data-bs-theme='dark'] input::placeholder {
  color: #fff !important;
}
[data-bs-theme='dark'] .ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: #ccc !important;
}

[data-bs-theme='dark'] .menu-title:hover,
[data-bs-theme='dark'] a,
[data-bs-theme='dark'] a:hover {
  color: #ccc !important;
}
[data-bs-theme='dark'] .form-check-input {
  border: 2px solid #fff !important;
}
