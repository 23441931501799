.slider_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100% !important;
  background-color: var(--color-primary);
  padding: 0;
  position: relative;
  padding: 0;
}
.slider__section {
  width: 100%;
  height: 100%;
  margin-top: 0 !important;
}

.slick-dots {
  bottom: -20px !important;
}
.slick-dots li.slick-active button::before {
  font-size: 1rem !important;
  background-color: #fff;
}

.slick-dots li button::before {
  font-size: 1rem !important;
  content: '';
  width: 0.7rem !important;
  height: 0.7rem !important;
  border-radius: 50% !important;
  border: 1px solid #fff;
  background-color: transparent;
  opacity: 0 !important;
}
.slick-track {
  height: 100% !important;
  max-height: 15rem !important;
}
.slick-list {
  height: 100% !important;
  min-height: 15rem !important;
  max-height: 15rem !important;
}

.slick-slider {
  min-height: 15rem !important;
  height: 100%;
  max-height: 15rem !important;
  margin-top: auto !important;
}
.slick-slide > div {
  height: 100% !important;
}

.slide {
  padding: 0 !important;
}
.slider__image__animation {
  animation: zoomin;
  animation-duration: 0.5s;
  img {
    height: 90vh !important;
    width: 90vw !important;
  }
}

.image-gallery-thumbnails-container,
.image-gallery-thumbnail {
  max-height: 9rem !important;
  height: 9rem !important;
  overflow: hidden !important;
}
.image-gallery-thumbnail-image {
  object-fit: cover !important;
}

.image-gallery-image {
  object-fit: cover !important;
  height: 15rem !important;
}
.image-gallery-thumbnails-wrapper {
  display: none !important;
}
.fullscreen .image-gallery-thumbnails-wrapper {
  display: block !important;
}
.fullscreen
  .image-gallery-slide-wrapper
  .image-gallery-swipe
  .image-gallery-slides
  .image-gallery-slide
  .image-gallery-image {
  object-fit: contain !important;
  height: 87vh !important;
}

.image-gallery-thumbnails-container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.image-gallery-thumbnail {
  height: 6rem !important;
}
.image-gallery-thumbnail-image {
  height: 6rem !important;
  object-fit: cover !important;
}
.fullscreen {
  overflow: none !important;
  .image-gallery,
  .image-gallery-content,
  .image-gallery-swipe,
  .image-gallery-slides,
  .image-gallery-slide {
    z-index: 100000 !important;
  }
}
.fullscreen {
  .enlarge__btn,
  .next__arrow,
  .back__arrow {
    z-index: 100001 !important;
  }
}
.fullscreen {
  .enlarge__btn {
    margin-top: 5rem !important;
    margin-right: 2rem !important;
    i {
      font-size: 2rem;
    }
  }
}
.enlarge__btn i {
  color: var(--bs-primary) !important;
  z-index: 100002 !important;
}
