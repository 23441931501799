.enlarge__btn {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 2;
  color: var(--bs-primary) !important;
}
.close__btn {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 2rem;
  top: 7rem;
  z-index: 10001;
}

.icon {
  font-size: 3rem;
  color: #ccc;
}
.icon__black {
  font-size: 2rem;
  color: #ccc;
}

.full__image__chat {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  background: linear-gradient(rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%);
  .image__container1__chat {
    padding-top: 0 !important;
  }
  .image__container__chat {
    position: relative;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    animation: zoomin;
    animation-duration: 0.5s;
    display: grid;
    place-items: center;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

@keyframes zoomin {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}
.slider__image__animation__chat {
  animation: zoomin;
  animation-duration: 0.5s;
  img {
    height: 90vh !important;
    width: 90vw !important;
  }
}
