.custom__row {
  &:hover {
    background-color: #f9f9f9;
  }
}

.ck-editor {
  z-index: 0 !important;
}
.link {
  &:hover h5 {
    color: var(--bs-menu-link-color-active);
  }

  cursor: pointer;
}

.accordion-button::after {
  display: none !important;
}
