.modalfade {
  animation: slideDown 0.3s;
  -moz-animation: slideDown 0.3s; /* Firefox */
  -webkit-animation: slideDown 0.3s; /* Safari and Chrome */
  -o-animation: slideDown 0.3s; /* Opera */
}
@keyframes slideDown {
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}
@-moz-keyframes slideDown {
  /* Firefox */
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}
@-webkit-keyframes slideDown {
  /* Safari and Chrome */
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}
@-o-keyframes slideDown {
  /* Opera */
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}
