//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: #ffffff;

// Theme colors
// Primary
$primary: #8d4290;
$primary-active: #b381b5;
$primary-light: #f1faff;
$primary-light-dark: #151521;
$primary-inverse: $white;
$primary-dark: #0e0e16;
$component-checked-bg: #8d4290;
$form-check-input-bg-solid: #ccc;
// Success
$success: #50cd89;
$success-active: #47be7d;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: $white;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: $white;

// Danger
$danger: #f1416c;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: $white;

// Warning
$warning: #ffc700;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: $white;
.btn-primary,
.btn.btn-light-primary:hover:not(.btn-active),
.btn.btn-light-primary:active:not(.btn-active),
.btn.btn-light-primary:focus:not(.btn-active),
.cutton-custom-variant:hover {
  background-color: var(--bs-button-primary-color) !important;
}

.form-group,
.form,
.modal,
.tab-content,
.buttons__container {
  .btn-check:active + .btn.btn-light-primary,
  .btn.btn-primary.show,
  .show > .btn.btn-primary,
  .btn-primary {
    background-color: var(--bs-button-primary-color) !important;
  }
  .btn.btn-light-primary:focus:not(.btn-active),
  .btn.btn-light-primary:hover:not(.btn-active),
  .btn.btn-light-primary:hover:not(.btn-active) i,
  .btn.btn-light-primary:active:not(.btn-active),
  .show > .btn.btn-primary:hover,
  .btn-primary:hover,
  .btn.btn-primary:hover:not(.btn-active),
  .btn.btn-primary:focus:not(.btn-active),
  .btn.btn-primary:active:not(.btn-active) {
    background-color: var(--bs-button-secondary-color) !important;
  }
  .btn.btn-light-primary {
    background-color: var(--bs-button-secondary-color) !important;
  }
}
.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary:hover:not(.btn-active),
.btn.btn-primary:active:not(.btn-active) {
  background-color: var(--bs-button-primary-color) !important;
}
.card {
  .btn.btn-primary:hover:not(.btn-active) {
    background-color: var(--bs-button-secondary-color) !important;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--bs-button-primary-color) !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-light-primary,
.btn-light-primary:focus {
  background-color: var(--bs-button-secondary-color) !important;
}

.form-check-input:checked {
  background-color: var(--bs-button-primary-color) !important;
}

[data-bs-theme='dark'] {
  .btn-light-primary {
    color: $white !important;
  }
  .btn:disabled {
    color: var(--bs-light-inverse) !important;
  }
}
