.menu__container {
  display: flex;
  align-items: start;
  height: min-content;
  transition: all ease-in-out 0.3s;
  position: relative;
  .chid__container {
    transition: all ease-in-out 0.3s;
    width: 100%;
  }
  .showMenu {
    width: 100%;
  }
  .actions__sideMenu {
    transition: all ease-in-out 0.3s;
    width: 0rem;
    visibility: hidden;
    height: 100%;
    overflow-y: scroll;
  }
  .sideMenuShow {
    width: 60%;
    visibility: visible;
    position: absolute;
    right: 0;
    background-color: white;
  }
}
