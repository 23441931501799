.MuiTab-root.Mui-selected {
  background-color: #ffffff !important;
  color: var(--bs-primary) !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
}
.MuiTab-root {
  background-color: var(--bs-primary) !important;
  color: #fff !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset !important;
}
.MuiTabs-indicator {
  display: none !important;
}

.kidsList {
  .card {
    border-radius: 0 !important;
  }
}

[data-bs-theme='dark'] .MuiTab-root.Mui-selected {
  background-color: #1e1e2d !important;
  color: #fff !important;
}
