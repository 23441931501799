.EventModal .modal-content {
  height: max-content;
}

.add__btn {
  border: 1px dashed var(--bs-primary) !important;
  border-radius: 0;
  &:hover {
    background-color: var(--bs-primary);
    .plusIcon {
      color: white !important;
    }
  }
}
