.MuiPickersDay-dayWithMargin {
  font-size: 1rem !important;
}

.datepicker {
  width: 100% !important;
}

.MuiOutlinedInput-input {
  height: 1rem !important;
  padding: 1.1rem 1rem !important;
  border-radius: 0.475rem !important;
}
