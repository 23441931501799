.search__input__container {
  border-radius: 20rem;
  width: 90%;
  transition: all ease-in-out 0.2s !important;
  &:focus-within {
    width: 100%;
  }
}
.search__input {
  outline: none !important;
  border: none !important;
  &:focus,
  &:focus-visible {
    outline: none !important;
    border: none !important;
  }
}
