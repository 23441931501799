.input__noborders {
  border: none !important;
  outline: none;
  &:hover,
  &:focus,
  &:focus-visible {
    border: none !important;
    outline: none;
  }
}

.chat__details {
  overflow: none !important;
  box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7);
}

.chat__background {
  // background-image: url('../../../../../public/chatbg.png');
  // background-repeat: repeat;
  // background-position: 50%;
  padding-bottom: 0 !important;
  overflow: none !important;
}

.boxShadow {
  box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7);
}

.chat__header__contact {
  border-bottom: 1px solid #eee;

  margin-bottom: 0 !important;

  // &:hover {
  //   background-color: #eee;
  //   cursor: pointer;
  // }
}

.group__tabs__container {
  button {
    border-radius: 0 !important;
  }
}

.selected__chat {
  &:hover {
    background-color: var(--bs-primary);
  }
  background-color: var(--bs-primary);
  * {
    color: #eee !important;
  }
  .badge {
    background-color: #eee !important;
    color: var(--bs-primary) !important;
  }
}

.inner__chat {
  padding-bottom: 8rem !important;
}
