[data-bs-theme='dark'] {
  .rdrDateRangePickerWrapper,
  .rdrDefinedRangesWrapper,
  .rdrDateRangeWrapper,
  .rdrStaticRanges,
  .rdrStaticRange,
  .model__container,
  .rdrDateDisplayWrapper,
  .rdrDateInput {
    background-color: #1e1e2d !important;
    color: #fff !important;
  }
  .rdrStaticRangeSelected {
    color: #eee !important;
    font-weight: bolder;
  }
  .rdrStaticRangeLabel:hover {
    color: #eee !important;
    font-weight: bolder;
    background-color: #363638 !important;
  }
  .rdrDayNumber,
  .rdrDayNumber span {
    color: #fff !important;
  }
  .rdrDay {
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge {
      background: #575758 !important;
      color: #575758 !important;
    }
  }
}
