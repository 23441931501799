.activity__card__item {
  animation: all 0.3s ease-in-out;
  cursor: pointer;
  min-height: 7rem;
  &:hover {
    background-color: var(--bs-secondary);

    .delete__icon {
      display: block;
    }
  }
  .delete__icon {
    @media (min-width: 900px) {
      display: none;
    }
  }
}
[data-bs-theme='dark'] {
  .activity__card__item:hover {
    background-color: #202020 !important;
  }
}
