.tag__container {
  &:hover {
    .tag__btn {
      width: 1.5rem;
      opacity: 1;
    }
  }
  .tag__btn {
    width: 0;
    opacity: 0;
    height: 100%;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    background-color: #ffbdad;
    align-items: center !important;
    @media (max-width: 900px) {
      width: 1.5rem;
      opacity: 1;
    }
    .icon {
      color: #de350b !important;
    }
  }
}
.add_btn {
  align-items: center !important;
}

.tag__btn__filter {
  background-color: #ffbdad;
  &:hover {
    background-color: #f6aa97;
  }
  .icon {
    color: #de350b !important;
  }
}
