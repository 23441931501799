.image__preview {
  &:hover {
    .overlay {
      opacity: 0.4;
      cursor: pointer;
      z-index: 1;
    }
    button {
      z-index: 2;
      opacity: 1;
      display: block;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f0f9fe;

    opacity: 0;
    border: 1px solid #cccc;
  }
  button {
    display: none;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out;
  }
}
